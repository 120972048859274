import CryptoJS from "crypto-js";

export default {
  computed: {
    queryOrganisationId() {
      let organisationId = "";
      if (this.$route.query.oi) {
        let tempOrganisationId = this.$route.query.oi.toString();
        tempOrganisationId = decodeURIComponent(tempOrganisationId);

        organisationId = CryptoJS.AES.decrypt(
          tempOrganisationId,
          process.env.VUE_APP_LOGIN_URL_SALT
        );
        organisationId = organisationId.toString(CryptoJS.enc.Utf8);
        this.$store.commit("auth/setOrganisationIdToken", tempOrganisationId);
      }
      return organisationId;
    },
  },
};
