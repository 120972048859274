<template>
  <v-card data-cy="onboarding-comp" flat>
    <v-card-text>
      <div class="text-center text-h5 mb-4">
        {{ $t("onboarding.title") }}
      </div>
      <div>
        {{ $t("auth.infoCas3Onboarding") }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text small :to="loginRoute">{{ $t("auth.backToLogin") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "OnboardingCas",
  mixins: [ComponentStatus],
  components: {},
  props: {
    organisationId: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    loginRoute() {
      let route = {
        name: "login",
      };
      if (this.organisationIdToken) {
        route.query = { oi: this.organisationIdToken };
      }

      return route;
    },
  },
  created() {
    if (this.lang) {
      this.$store.commit("auth/setOrganisationLanguage", this.lang);
    }
    if (this.organisationId) {
      this.$store.dispatch(
        "organisations/readAppearanceById",
        this.organisationId
      );
    }
  },
};
</script>
