<template>
  <v-card data-cy="onboarding-comp" flat>
    <v-card-text>
      <div class="text-center text-h5 mb-4">
        {{ $t("onboarding.title") }}
      </div>
      <v-alert type="info" outlined class="mb-4">
        <div v-html="$t('onboarding.info')"></div>
      </v-alert>
      <v-text-field
        v-model="editEmail"
        prepend-icon="mdi-account"
        :label="$t('auth.login')"
        type="text"
      >
      </v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="clickSendLink">{{
        $t("auth.forgotPasswordSendLink")
      }}</v-btn>
    </v-card-actions>

    <v-card-actions>
      <v-spacer />
      <v-btn text small :to="loginRoute">{{ $t("auth.backToLogin") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "Onboarding",
  mixins: [ComponentStatus],
  components: {},
  props: {
    organisationId: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    // Edit proxy form data
    editEmail: "",
  }),
  computed: {
    loginRoute() {
      let route = {
        name: "login",
      };
      if (this.organisationIdToken) {
        route.query = { oi: this.organisationIdToken };
      }

      return route;
    },
  },
  created() {
    if (this.lang) {
      this.$store.commit("auth/setOrganisationLanguage", this.lang);
    }
    if (this.organisationId) {
      this.$store.dispatch(
        "organisations/readAppearanceById",
        this.organisationId
      );
    }
  },
  watch: {
    email: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && this.editEmail === "") {
          this.editEmail = newValue;
        }
      },
    },
  },
  methods: {
    clickSendLink() {
      this.setStatusReading();
      let payload = {
        email: this.editEmail,
      };
      this.$store
        .dispatch("auth/forgotPassword", payload)
        .then((/* result */) => {
          this.setStatusReadSuccess();
          this.$store.commit(
            "status/showSuccess",
            this.$t("auth.forgotPasswordSuccess")
          );
        })
        .catch((/* error */) => {
          this.setStatusReadError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
